import React from 'react';
import { ExtendedRouteObject } from '@/types';
import ROUTE_CONSTANTS from '@/routes/route-constants';

import { BatchSMS, ViewInstance, CreateBatchSMS } from '@/routes/lazy-component-imports';

const BatchSMSRouteObject: ExtendedRouteObject[] = [
  {
    path: ROUTE_CONSTANTS.BATCH_SMS,
    element: <BatchSMS />,
    params: {
      title: 'Batch SMS',
      translationId: 'Portal.BatchSMS',
    },
  },
  {
    path: ROUTE_CONSTANTS.VIEW_BATCH_SMS,
    element: <ViewInstance />,
    params: {
      title: 'Batch SMS',
    },
  },
  {
    path: ROUTE_CONSTANTS.CREATE_BATCH_SMS,
    element: <CreateBatchSMS />,
    params: {
      title: 'Create Batch SMS',
      translationId: 'Portal.CreateBatchSMS',
    },
  },
];
export default BatchSMSRouteObject;
