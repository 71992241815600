import axios, { Axios } from 'axios';
import ConfigurationManager from '@/config/ConfigurationManager';
import { getSessionToken } from '@/Identity/access';

export const download = (client, options) => {
  const o = options || {};

  if (!client) {
    throw new Error('No "client" has been specified.');
  }

  o.method = o.method || 'post';
  o.fileName = o.fileName || 'file';

  if (!o.path) {
    throw new Error('No "path" option has been specified.');
  }

  switch (o.method.toLowerCase()) {
    case 'get':
    case 'post': {
      break;
    }
    default: {
      throw new Error(`Unsupport "method" option: ${o.method}`);
    }
  }

  return client.post(o.path, o.body || {}, {
    responseType: 'blob'
  })
    .then((response) => {
      const contentDisposition =
        response.headers['content-disposition'];

      let fileName = o.fileName;

      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename="(.+)"/);

        if (fileNameMatch.length === 2) {
          fileName = fileNameMatch[1];
        }
      }

      const href = URL.createObjectURL(response.data);
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });
};

export default class AxiosClient extends Axios {
  constructor(endpoint, timeout = 100000) {
    var instance = axios.create({
      baseURL: endpoint,
      timeout: timeout,
      headers: { Accept: 'application/json' },
    });

    instance.defaults.headers.common['Accept'] = 'application/json';
    instance.defaults.headers.post['Content-Type'] = 'application/json';

    instance.interceptors.request.use(function (config) {
      config.headers['Authorization'] = `Bearer ${getSessionToken()}`;
      return config;
    });

    instance.interceptors.response.use(async function (response) {
      const resultCode = (response?.data?.ResultCode ?? 0);

      if (resultCode === 802) {
        // Invalid session token
        await ConfigurationManager.setCurrentSession(null);
        window.location.href = '/';
      }

      return response;
    }, function (error) {
      return Promise.reject(error);
    });

    return instance;
  }
}