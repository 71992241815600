const PROMOTIONS_CONSTANTS = {
  PROMOTIONS: 'Promotions',
  PROMOTION_UPLOAD: 'Promotions/PromotionUpload',
  PROMOTION_ADD: 'Promotions/Add/',
  VIEW_PROMOTION: 'Promotions/ViewPromotion/:promotionUid',
  CREATE_PROMO_RULE: 'CreatePromoRule/:promotionUid',
  VIEW_PROMO_RULE: 'ViewRule/:promotionUid/:ruleUid',
  RULE_BUILDER_CREATE_RULE: 'RuleBuilder/CreateRule/:promotionUid',
  RULE_BUILDER_VIEW_RULE: 'RuleBuilder/ViewRule/:promotionUid/:ruleUid',
  RULE_BUILDER_UPDATE_RULE: 'RuleBuilder/UpdateRule/:promotionUid/:ruleUid',
  PROMOTIONS_CALENDAR: 'PromotionsCalendar',
  VOUCHER_MANAGER: 'VoucherManager',
  VOUCHER_MANAGER_VIEW_VOUCHER: 'VoucherManager/:voucherBookUid',
  SPECIAL_DATES: 'SpecialDates',
};
export default PROMOTIONS_CONSTANTS;
