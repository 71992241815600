import React from 'react';
import { ExtendedRouteObject } from '@/types';
import ROUTE_CONSTANTS from '@/routes/route-constants';
import {
  BrandOwners,
  BrandOwner,
  ViewBrand,
  Companies,
  CompanyProfile,
  FranchiseGroups,
  EntityTags,
  TraderGroups,
  Traders,
  TraderProfile,
} from '@/routes/lazy-component-imports';

const CompaniesRouterObject: ExtendedRouteObject[] = [
  {
    path: ROUTE_CONSTANTS.BRAND_OWNERS,
    element: <BrandOwners />,
    params: {
      title: 'Brands',
    },
  },
  {
    path: ROUTE_CONSTANTS.BRAND_OWNER,
    element: <BrandOwner />,
    params: {
      title: 'Brand Owner',
    },
  },
  {
    path: ROUTE_CONSTANTS.VIEW_BRAND,
    element: <ViewBrand />,
    params: {
      title: 'Brand',
    },
  },
  {
    path: ROUTE_CONSTANTS.COMPANIES,
    element: <Companies />,
    params: {
      title: 'Companies',
      translationId: 'Portal.Companies',
    },
  },
  {
    path: ROUTE_CONSTANTS.COMPANY_PROFILE,
    element: <CompanyProfile />,
    params: {
      title: 'Company Profile',
      translationId: 'Portal.CompanyProfile',
    },
  },
  {
    path: ROUTE_CONSTANTS.FRANCHISE_GROUPS,
    element: <FranchiseGroups />,
    params: {
      title: 'Franchise Groups',
      translationId: 'Portal.FranchiseGroups',
    },
  },
  {
    path: ROUTE_CONSTANTS.ENTITY_TAG,
    element: <EntityTags />,
    params: {
      title: 'Entity Tags',
      translationId: 'Portal.EntityTags',
    },
  },
  {
    path: ROUTE_CONSTANTS.TRADER_GROUPS,
    element: <TraderGroups />,
    params: {
      title: 'Trader Groups',
      translationId: 'Portal.TraderGroups',
    },
  },
  {
    path: ROUTE_CONSTANTS.TRADER_GROUP,
    element: <TraderGroups />,
    params: {
      title: 'Trader Groups',
      translationId: 'Portal.TraderGroups',
    },
  },
  {
    path: ROUTE_CONSTANTS.TRADERS,
    element: <Traders />,
    params: {
      title: 'Traders',
      translationId: 'Portal.Traders',
    },
  },
  {
    path: ROUTE_CONSTANTS.TRADER_PROFILE,
    element: <TraderProfile />,
    params: {
      title: 'Trader Profile',
      translationId: 'Portal.TraderProfile',
    },
  },
];

export default CompaniesRouterObject;
