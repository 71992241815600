import React from 'react';
import { ExtendedRouteObject } from '@/types';
import ROUTE_CONSTANTS from '@/routes/route-constants';

import { Correspondence } from '@/routes/lazy-component-imports';

const CorrespondenceRouteObject: ExtendedRouteObject[] = [
  {
    path: ROUTE_CONSTANTS.CORRESPONDENCE,
    element: <Correspondence />,
    params: {
      title: 'Correspondence - Templates',
      translationId: 'Portal.CorrespondenceTemplates',
    },
  },
  {
    path: ROUTE_CONSTANTS.VIEW_CORRESPONDENCE,
    element: <Correspondence />,
    params: {
      title: 'Correspondence - Templates',
      translationId: 'Portal.CorrespondenceTemplates',
    },
  },
];
export default CorrespondenceRouteObject;
