import { userSelector } from '../SolutionSlice';
import watch from 'redux-watch';
import AxiosClient from '../../Helpers/AxiosClient';
import { setTouchsidesCatalogUID } from '@/Store/CatalogSlice';

const wire = store => {
  const userWatcher = watch(() => userSelector(store.getState()));

  store.subscribe(
    userWatcher(async user => {
      if (!user || user.IsPlatformUser) {
        return;
      }

      const catalogClient = new AxiosClient(store.getState().endpoints.endpoints.Catalog);

      if (!user.SessionToken) {
        return;
      }

      await catalogClient.get('/Touchsides?sessionToken=' + user.SessionToken).then(res => {
        if (res.data.ResultCode !== 0) {
          return;
        }

        store.dispatch(setTouchsidesCatalogUID(res.data.Item.UID));
      });
    })
  );
};

export default wire;
