import React, { useContext } from 'react';
import axios, { AxiosRequestConfig, AxiosResponse, AxiosError, AxiosResponseHeaders } from 'axios';
import IResponse from '@/OutletOwnerApp/interfaces/response.interface';
import { useState, useEffect } from 'react';
import configurationManager from '@/config/ConfigurationManager';
import { AppContext } from '@/contexts/app.context';

const environment = configurationManager.getEnvironment();

const axiosAPI = axios.create({
  baseURL: environment.apiEndpointPrefix,
  timeout: 100000,
  headers: {
    'Content-Type': 'application/json',
  },
});

const httpService = {
  UseHttp: (method: string, url: string, body: any | ''): IResponse => {
    const [response, setResponse] = useState<AxiosResponse>();
    const [error, setError] = useState<AxiosError>();
    const [loading, setLoading] = useState(true);
    const [responseHeaders, setResponseHeaders] = useState<AxiosResponseHeaders | undefined>();
    const [shouldRefetch, refetch] = useState({});

    const appContext = useContext(AppContext);

    const axiosParams: AxiosRequestConfig = {
      method,
      url,
      data: body,
    };

    const fetchData = async (params: AxiosRequestConfig) => {
      try {
        setLoading(true);
        const response = await axiosAPI.request(params);
        const resultCode = (response.data.ResultCode ?? 0);

        switch (resultCode) {
          case 0: {
            setError(undefined);
            setResponse(response);
            break;
          }
          case 802: {
            // Invalid session token
            appContext.logOut();
            break;
          }
          default: {
            setError(response.data.ResultMessage ?? 'Unknown server error');
            break;
          }
        }

        setResponseHeaders(response.headers as AxiosResponseHeaders);
      } catch (err: any) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    useEffect(() => {
      fetchData(axiosParams);
    }, [shouldRefetch]);

    return { response, error, loading, responseHeaders, refetch };
  },
  $request: async (requestConfig: AxiosRequestConfig): Promise<AxiosResponse> => {
    /*
    Check if we have a valid access token. If not, get one.
    */
    requestConfig.headers = {
      ...requestConfig.headers,
    };
    return await axiosAPI.request(requestConfig);
  },
};
export default httpService;
