import React, { useState, useEffect } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';
import { Segment } from 'semantic-ui-react';

import packageJson from '../package.json';
import ConfigurationManager from './config/ConfigurationManager';
import { useIntl } from 'react-intl';
import { setEndpoints as setGlobalEndpoints } from './Store/EndpointsSlice';

import { OnlineStatusProvider, useOnlineStatus } from './Helpers/OnlineDetector';
import { AppContext } from '@/contexts/app.context';
import AppLoader from '@/appshell/AppLoader';

import '@/fomantic/dist/semantic.css'; // custom build of fomantic
import '@/styles/portal-styles.scss';
import '@/styles/sizamina-styles.scss';
import '@/tailwind-generated.css';

import LegalBanner from '@/Portal/Common/LegalBanner';
import { useAppDispatch, useAppSelector } from '@/Helpers/hooks';
import { setSolution, setUser, userSelector } from '@/Store/SolutionSlice';

const App = () => {
  const anonymousPaths = ['/SignIn', '/terms-and-conditions', '/cookie-policy', '/frequently-asked-questions', '/'];
  const dispatch = useAppDispatch();
  const useSelector = useAppSelector;
  const user = useSelector(userSelector);
  const isOnline = useOnlineStatus();
  const [loggedIn, setLoggedIn] = useState(false);
  const [solutionData, setSolutionData] = useState(undefined);
  const [endpoints, setEndpoints] = useState(undefined);
  const [initialized, setInitialized] = useState(false);
  const [ready, setReady] = useState(false);
  const [errorLoading, setErrorLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [redirect, setRedirect] = useState(0);
  const [environment, setEnvironment] = useState(undefined);
  const [manuallyLoggedOut, setManuallyLoggedOut] = useState(false);
  const navigate = useNavigate();
  const [appState, setAppState] = useState({});
  const [providerValues, setProviderValues] = useState({});
  const [shopperName, setShopperName] = useState('');
  const intl = useIntl();

  const changeShopperName = name => {
    if (shopperName !== name) {
      setShopperName(name);
      localStorage.setItem('shopperName', name);
    }
  };

  useEffect(() => {
    const cachedShopperName = localStorage.getItem('shopperName');
    if (cachedShopperName) {
      setShopperName(cachedShopperName);
    }
  }, []);
  

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  // called by the sign in page when a user successfully logs in
  const logIn = async data => {
    await ConfigurationManager.setCurrentSession({
      token: data.user.SessionToken,
      solution: data.user.SolutionName,
      user: data.user
    });

    const settings = { ...data };

    delete settings['user'];

    setSolutionData(data);
    dispatch(
      setSolution({
        user: data.user,
        settings: settings,
      })
    );
    setLoggedIn(true);
  };

  const logOut = async () => {
    for (var i = 0; i < localStorage.length; i++) {
      if (localStorage.key(i) !== 'cookieBanner') {
        localStorage.removeItem(localStorage.key(i));
      }
    }
    await ConfigurationManager.setCurrentSession(null); // clears sessionToken
    setManuallyLoggedOut(true); // prevents automatically logging back in
    setLoggedIn(false); // shows sign in page
    dispatch(
      setUser({
        user: { ...user, Permissions: [] },
      })
    );

    if (!anonymousPaths.includes(window.location.pathname)) {
      console.log(window.location.pathname);
      window.location.href = '/';
    }
  };

  const setLocale = locale => {
    setSolutionData(prev => {
      return { ...prev, locale };
    });
  };

  useEffect(() => {
    if (!endpoints || !solutionData) {
      return;
    }

    setAppState({
      endpoints: endpoints,
      baseUrl: '/',
      token: solutionData.user?.SessionToken,
      solutionName: solutionData.user?.SolutionName,
      deploymentId: packageJson.version,
      loggedIn: loggedIn,
      logOut: logOut,
      logIn: logIn,
      setLocale: setLocale,
      intl: intl,
      shopperName: shopperName,
      changeShopperName: changeShopperName,
      ...solutionData,
    });

    setInitialized(true);
  }, [endpoints, solutionData]);

  useEffect(() => {
    if (!initialized) {
      return;
    }

    setReady(true);
  }, [initialized]);

  useEffect(() => {
    if (appState) setProviderValues(appState);
  }, [appState]);

  // called by navbar when swapping sessions, or used to set current current session when page is loaded
  const selectSession = session => {
    ConfigurationManager.getSolutionDataFor(session)
      .then(data => {
        setSolutionData(data);
        dispatch(
          setSolution({
            user: data.user,
            settings: data.settings,
            name: data.solutionName,
          })
        );

        setRedirect(redirect + 1);
        setLoggedIn(true);
      })
      .catch(err => {
        if (err instanceof Error) {
          setErrorMessage(err.message);
        } else {
          setErrorMessage(err);
        }

        logOut();
      });
  };

  // when page is loaded, make sure endpoints are cached, try find the current session and set up props to portal as they were before (refresh/new tab/etc)
  useEffect(() => {
    if (ready) {
      return;
    }
    const env = ConfigurationManager.getEnvironment();
    setEnvironment(env);
    ConfigurationManager.endpointsAsync()
      .then(endpointsReceived => {
        setEndpoints(endpointsReceived);
        dispatch(setGlobalEndpoints(endpointsReceived));

        if (!manuallyLoggedOut) {
          ConfigurationManager.getCurrentSession().then(session => {
            if (session !== undefined) {
              selectSession(session);
            } else {
              logOut();
              setSolutionData({});
            }
          });
        }
      })
      .catch(err => {
        if (err instanceof Error) setErrorMessage(err.message);
        else setErrorMessage(err);

        setErrorLoading(true);
      });
  }, []);

  // Handle redirects when loggedIn changes
  useEffect(() => {
    if (!loggedIn || !user || window.location.pathname !== '/') {
      return;
    }

    navigate((user.Context || 'portal').toLowerCase() === 'portal' ? '/portal' : '/outlets');
  }, [loggedIn]);

  if (errorLoading) return <h2 style={{ color: 'red' }}>Error loading: {errorMessage}</h2>;

  if (!ready || (loggedIn && !providerValues.user))
    return (
      <div>
        <AppLoader />
        <div>
          ({!endpoints && <div>Getting Endpoints...</div>}) <br /> ({!environment && <div>Detecting Environment...</div>}) (
          {!solutionData && <div>Getting Session data...</div>})
        </div>
      </div>
    );

  return (
    <>
      <AppContext.Provider value={providerValues}>
        <div className={!loggedIn ? 'login' : ''}>
          <OnlineStatusProvider>
            {isOnline ? (
              <Outlet />
            ) : (
              <Segment>
                <h1>Please connect to the internet to sign in</h1>
              </Segment>
            )}
            <LegalBanner />
          </OnlineStatusProvider>
        </div>
      </AppContext.Provider>
    </>
  );
};

export default App;
