import React from 'react';
import { ExtendedRouteObject } from '@/types';
import ROUTE_CONSTANTS from '@/routes/route-constants';

import { TraderAccounts, TraderInventory, TraderUpload, OpsTraderList } from '@/routes/lazy-component-imports';

const TradersRouteObject: ExtendedRouteObject[] = [
  {
    path: ROUTE_CONSTANTS.TRADER_ACCOUNTS,
    element: <TraderAccounts />,
    params: {
      title: 'Trader Accounts',
    },
  },
  {
    path: ROUTE_CONSTANTS.TRADER_INVENTORY,
    element: <TraderInventory />,
    params: {
      title: 'Inventory',
    },
  },
  {
    path: ROUTE_CONSTANTS.TRADER_UPLOAD,
    element: <TraderUpload />,
    params: {
      title: 'Trader Bulk Management',
      translationId: 'Portal.TraderBulkManagement',
    },
  },
  {
    path: ROUTE_CONSTANTS.OPS_TRADER_LIST,
    element: (
      <>
        <div className='innermodalcontents widget' style={{ marginTop: 0, backgroundColor: 'white', marginBottom: '8em' }}>
          <OpsTraderList />
        </div>
      </>
    ),
  },
];
export default TradersRouteObject;
