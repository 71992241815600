import React, { createContext } from 'react';
import { IEndpointUrls, IUser } from '@/types';

export interface IAppContext {
  accessToken: string;
  endpoints: IEndpointUrls;
  baseUrl: string;
  token: string;
  deploymentId: string;
  loggedIn: boolean;
  logOut: () => void;
  logIn: (a: Array<object>) => void;
  setLocale: () => void;
  solutionName: string;
  whitelabel?: string;
  solutionType?: string;
  airtimeEnabled?: boolean;
  user?: IUser;
  intl?: any;
  country?: string;
  locale: string;
  currency?: string;
  timezone?: string;
  userType?: string;
  isDynamicsCRMSolution?: string;
}

export const AppContext = createContext<IAppContext>({
  accessToken: '',
  endpoints: {
    AsterixCompany: '',
    Company: '',
    AsterixGeneral: '',
    HashExceptions: '',
    BrandOwner: '',
    BulkManagement: '',
    BulkSms: '',
    Calendar: '',
    Card: '',
    CardBlacklist: '',
    Catalog: '',
    CheatSheet: '',
    Configuration: '',
    Dashboard: '',
    DCRMSupportTool: '',
    DataQuery: '',
    Device: '',
    DevicePackage: '',
    Email: '',
    EntityProperties: '',
    FieldOps: '',
    FranchiseGroup: '',
    General: '',
    Group: '',
    Html2Pdf: '',
    Manufacturer: '',
    Operator: '',
    PersistentLists: '',
    Person: '',
    Processor: '',
    Product: '',
    ProductClassification: '',
    ProductTree: '',
    Promotion: '',
    RemoteQuery: '',
    RetailProduct: '',
    Sales: '',
    Security: '',
    Sessions: '',
    Shopper: '',
    Sms: '',
    Subscription: '',
    Supplier: '',
    System: '',
    SystemAsterix: '',
    Tax: '',
    Template: '',
    Trader: '',
    TraderAccounts: '',
    TraderOwnerReport: '',
    TraderWallet: '',
    Wallet: '',
    WalletAsterix: '',
    Website: '',
    PromotionRuleBuilder: '',
  },
  baseUrl: '',
  token: '',
  deploymentId: '',
  loggedIn: false,
  logOut: () => { },
  logIn: ([]) => { },
  setLocale: () => { },
  solutionName: '',
  locale: 'en-ZA',
});
