import React from 'react';
import { ExtendedRouteObject } from '@/types';
import ROUTE_CONSTANTS from '@/routes/route-constants';

import { Products, CreateRetailProduct, ViewRetailProduct } from '@/routes/lazy-component-imports';

const ProductsRouteObject: ExtendedRouteObject[] = [
  {
    path: ROUTE_CONSTANTS.PRODUCTS,
    element: <Products />,
    params: {
      title: 'Products',
      translationId: 'Portal.Products',
    },
  },
  {
    path: ROUTE_CONSTANTS.CREATE_PRODUCT,
    element: <CreateRetailProduct />,
    params: {
      title: 'Create Product',
    },
  },
  {
    path: ROUTE_CONSTANTS.VIEW_PRODUCT,
    element: <ViewRetailProduct />,
    params: {
      title: 'Product',
    },
  },
];
export default ProductsRouteObject;
