const Country: any = {
  SouthAfrica: {
    DefaultCulture: 'en-ZA',
    DefaultLanguage: 'English',
    SupportedLanguages: ['English', 'Zulu'],
    Code: 'ZA',
    Currency: {
      Culture: 'en-ZA',
      Symbol: 'R',
      Code: 'ZAR',
    },
  },
  UnitedStatesOfAmerica: {
    DefaultCulture: 'en-US',
    DefaultLanguage: 'English',
    SupportedLanguages: ['English'],
    Code: 'US',
    Currency: {
      Culture: 'en-US',
      Symbol: '$',
      Code: 'USD',
    },
  },
  China: {
    DefaultCulture: 'zh-CN',
    DefaultLanguage: 'Chinese',
    SupportedLanguages: ['English', 'Chinese'],
    Code: 'CN',
    Currency: {
      Culture: 'zh-CN',
      Symbol: '¥',
      Code: 'CNY',
    },
  },
  India: {
    DefaultCulture: 'hi-IN',
    DefaultLanguage: 'Hindi',
    SupportedLanguages: ['English', 'Hindi'],
    Code: 'IN',
    Currency: {
      Culture: 'hi-IN',
      Symbol: '₹',
      Code: 'INR',
    },
  },
  Botswana: {
    DefaultCulture: 'en-BW',
    DefaultLanguage: 'English',
    SupportedLanguages: ['English', 'Zulu'],
    Code: 'BW',
    Currency: {
      Culture: 'en-BW',
      Symbol: 'P',
      Code: 'BWP',
    },
  },
  Nigeria: {
    DefaultCulture: 'en-NG',
    DefaultLanguage: 'English',
    SupportedLanguages: ['English', 'Zulu'],
    Code: 'NG',
    Currency: {
      Culture: 'en-NG',
      Symbol: '₦',
      Code: 'NGN',
    },
  },
  Mexico: {
    DefaultCulture: 'es-MX',
    DefaultLanguage: 'English',
    SupportedLanguages: ['English', 'SpanishMexico'],
    Code: 'MX',
    Currency: {
      Culture: 'es-MX',
      Symbol: '$',
      Code: 'MXN',
    },
  },
  Brazil: {
    DefaultCulture: 'pt-BR',
    DefaultLanguage: 'English',
    SupportedLanguages: ['English', 'PortugueseBrazil'],
    Code: 'BR',
    Currency: {
      Culture: 'pt-BR',
      Symbol: 'R$',
      Code: 'BRL',
    },
  },
  Vietnam: {
    DefaultCulture: 'vi-VN',
    DefaultLanguage: 'English',
    SupportedLanguages: ['English', 'Vietnamese'],
    Code: 'VN',
    Currency: {
      Culture: 'vi-VN',
      Symbol: '₫',
      Code: 'VND',
    },
  },
  Poland: {
    DefaultCulture: 'pl-PL',
    DefaultLanguage: 'English',
    SupportedLanguages: ['English', 'Polish'],
    Code: 'PL',
    Currency: {
      Culture: 'pl-PL',
      Symbol: 'zł',
      Code: 'PLN',
    },
  },
  UnitedKingdom: {
    DefaultCulture: 'en-GB',
    DefaultLanguage: 'English',
    SupportedLanguages: ['English'],
    Code: 'GB',
    Currency: {
      Culture: 'en-GB',
      Symbol: '£',
      Code: 'GBP',
    },
  },
  Ethiopia: {
    DefaultCulture: 'am-ET',
    DefaultLanguage: 'Amharic',
    SupportedLanguages: ['English', 'Amharic'],
    Code: 'ET',
    Currency: {
      Culture: 'am-ET',
      Symbol: 'ብር',
      Code: 'ETB',
    },
  },
  Rwanda: {
    DefaultCulture: 'rw-RW',
    DefaultLanguage: 'Kinyarwanda',
    SupportedLanguages: ['English', 'Kinyarwanda'],
    Code: 'RW',
    Currency: {
      Culture: 'rw-RW',
      Symbol: 'R₣',
      Code: 'RWF',
    },
  },
  Russia: {
    DefaultCulture: 'sah-RU',
    DefaultLanguage: 'Yakut',
    SupportedLanguages: ['English', 'Yakut'],
    Code: 'RU',
    Currency: {
      Culture: 'sah-RU',
      Symbol: '₽',
      Code: 'RUB',
    },
  },
};

export default Country;
