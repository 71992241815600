import React from 'react';
import { ExtendedRouteObject } from '@/types';
import ROUTE_CONSTANTS from '@/routes/route-constants';

import { TraderStatementOfAccount, TraderCashbackTendered, BasketHashExceptions, HashExceptions, DeviceHash } from '@/routes/lazy-component-imports';

const ReportsRouteObject: ExtendedRouteObject[] = [
  {
    path: ROUTE_CONSTANTS.REPORT_DEVICE_HASH,
    element: <DeviceHash />,
    params: {
      title: 'Device Hash',
    },
  },
  {
    path: ROUTE_CONSTANTS.REPORT_BASKET_HASH_EXCEPTIONS,
    element: <BasketHashExceptions />,
    params: {
      title: 'Basket Hash Exceptions',
    },
  },
  {
    path: ROUTE_CONSTANTS.REPORT_HASH_EXCEPTIONS,
    element: <HashExceptions />,
    params: {
      title: 'Hash Exceptions',
    },
  },
  {
    path: ROUTE_CONSTANTS.REPORT_CASHBACK_TENDERED,
    element: <TraderCashbackTendered />,
    params: {
      title: 'Cashback Tender Report',
    },
  },
  {
    path: ROUTE_CONSTANTS.REPORT_STATEMENT_OF_ACCOUNT,
    element: <TraderStatementOfAccount />,
    params: {
      title: 'Retailer Statement of Account',
    },
  },
];
export default ReportsRouteObject;
