import { createSelector, createSlice } from '@reduxjs/toolkit';

const initialState = {
  endpoints: null,
};

export const endpointSlice = createSlice({
  name: 'endpoints',
  initialState,
  reducers: {
    setEndpoints: (state, action) => {
      state.endpoints = action.payload;
    },
  },
});

export const selectEndpoints = state => state.endpoints.endpoints;
export const endpointsSelector = createSelector(selectEndpoints, endpoints => endpoints);

// Action creators are generated for each case reducer function
export const { setEndpoints } = endpointSlice.actions;

export default endpointSlice.reducer;
