import React from 'react';
import { RouteObject } from 'react-router-dom';
import App from '@/App';
import ErrorBoundary from '@/ErrorBoundary';
import { ResetPassword, ChangePassword, OutletSignIn, SignIn } from '@/routes/lazy-component-imports';
import PortalRoutes from './portal-routes';
import SizaminaRoutes from './sizamina-routes';
import ROUTE_CONSTANTS from './route-constants';

const commonRoutes: RouteObject[] = [
  {
    path: ROUTE_CONSTANTS.APP,
    element: <App />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: '',
        index: true,
        element: <OutletSignIn />,
      },
      {
        path: ROUTE_CONSTANTS.TOUCHSIDES_SIGN_IN,
        element: <SignIn />,
      },
      {
        path: ROUTE_CONSTANTS.TOUCHSIDES_RESET_PASSWORD,
        element: <ResetPassword />,
      },
      {
        path: ROUTE_CONSTANTS.TOUCHSIDES_CHANGE_PASSWORD,
        element: <ChangePassword />,
      },
      ...PortalRoutes,
      ...SizaminaRoutes,
    ],
  },
];

export default commonRoutes;
