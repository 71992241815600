const SOLUTIONS_CONSTANTS = {
  SOLUTIONS: 'Solutions',
  USERS: 'Users',
  USER: 'Users/:credentialUid',
  ROLE_PERMISSIONS: 'RolePermissions',
  ROLE_PERMISSIONS_SOLUTION: 'RolePermissions/:solution',
  AUDIT_LOG: 'AuditLog',
  SETTINGS: 'Settings',
  TASKS: 'Tasks',
  BULK_WIZARD: 'BulkWizard',
};
export default SOLUTIONS_CONSTANTS;
