const COMPANIES_CONSTANTS = {
  BRAND_OWNERS: 'BrandOwners',
  BRAND_OWNER: 'BrandOwners/:OwnerId',
  VIEW_BRAND: 'ViewBrand/:brandUID',
  COMPANIES: 'Companies',
  COMPANY_PROFILE: 'CompanyProfile/:CompanyUID',
  FRANCHISE_GROUPS: 'FranchiseGroups',
  ENTITY_TAG: 'EntityTag',
  TRADER_GROUPS: 'TraderGroups',
  TRADER_GROUP: 'TraderGroups/:traderGroupUid',
  TRADERS: 'Traders',
  TRADER_PROFILE: 'TraderProfile/:TraderId',
};
export default COMPANIES_CONSTANTS;
