import { lazy } from 'react';
export const DeviceList = lazy(() => import('@/Portal/Devices/DeviceList'));
export const BulkDevices = lazy(() => import('@/Portal/Devices/BulkDevices'));
export const ViewDevice = lazy(() => import('@/Portal/Devices/ViewDevice'));

export const CardList = lazy(() => import('@/Portal/CardList'));
export const ShoppersPage = lazy(() => import('@/Portal/PersonList'));
export const ShopperPage = lazy(() => import('@/Portal/Shoppers/ShopperProfile'));

export const SyncProfiles = lazy(() => import('@/Portal/SyncProfiles/SyncProfiles'));
export const CreateSyncProfile = lazy(() => import('@/Portal/SyncProfiles/CreateSyncProfile'));
export const ViewSyncProfile = lazy(() => import('@/Portal/SyncProfiles/ViewSyncProfile'));
