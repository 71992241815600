import React from 'react';
import { Box } from '@mui/material';
import Progress from '@/components/Progress';
import touchSideLogo from '@/img/logo.png';
const AppLoader = () => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', height: '100vh' }}>
      <Box sx={{ m: 'auto', width: 200 }}>
        <Box sx={{ m: 2 }}>
          <img className='block h-[30px] w-auto' src={touchSideLogo} alt='Touchsides' />
        </Box>
        <Progress />
      </Box>
    </Box>
  );
};

export default AppLoader;
