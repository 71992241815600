import React from 'react';
import { ExtendedRouteObject } from '@/types';
import ROUTE_CONSTANTS from '@/routes/route-constants';

import { Solutions, Users, RolePermissions, AuditLog, Settings, FileProcessing, BulkWizard } from '@/routes/lazy-component-imports';

const SolutionsRouteObject: ExtendedRouteObject[] = [
  {
    path: ROUTE_CONSTANTS.SOLUTIONS,
    element: <Solutions />,
  },
  {
    path: ROUTE_CONSTANTS.USERS,
    element: <Users />,
    params: {
      title: 'Users',
    },
  },
  {
    path: ROUTE_CONSTANTS.USER,
    element: <Users />,
    params: {
      title: 'User',
    },
  },
  {
    path: ROUTE_CONSTANTS.ROLE_PERMISSIONS,
    element: <RolePermissions />,
    params: {
      title: 'Roles and Permissions',
    },
  },
  {
    path: ROUTE_CONSTANTS.ROLE_PERMISSIONS_SOLUTION,
    element: <RolePermissions />,
    params: {
      title: 'Roles and Permissions',
    },
  },
  {
    path: ROUTE_CONSTANTS.AUDIT_LOG,
    element: <AuditLog />,
    params: {
      title: 'Audit Log',
    },
  },
  {
    path: ROUTE_CONSTANTS.SETTINGS,
    element: <Settings />,
    params: {
      title: 'Settings',
    },
  },
  {
    path: ROUTE_CONSTANTS.TASKS,
    element: <FileProcessing />,
    params: {
      title: 'Tasks',
    },
  },
  {
    path: ROUTE_CONSTANTS.BULK_WIZARD,
    element: <BulkWizard />,
    params: {
      title: 'Bulk Wizard',
    },
  },
];
export default SolutionsRouteObject;
