import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import IStockDetailResult from '@/OutletOwnerApp/interfaces/stockDetailResult.interface';
import traderOwnerStockService from '@/OutletOwnerApp/services/traderOwnerStock.service';

let requestPayload: any = {
  siteCode: '',
  page: 0,
  records: 20,
};

export const getStock = createAsyncThunk('stock/getStock', async (stockRequestPayload: any) => {
  // get the current stockrequestpayload from the stock slice and add the payload to it
  requestPayload = {
    siteCode: requestPayload.siteCode,
    page: requestPayload.page,
    records: requestPayload.records,
    ...stockRequestPayload,
  };
  const response = await traderOwnerStockService.getStockPaged(requestPayload);
  return response.data;
});

export const getRelatedProducts = createAsyncThunk('stock/getRelatedProducts', async (relatedProductsPayload: any) => {

  const response = await traderOwnerStockService.getRelatedProducts(relatedProductsPayload);
  return response.data;
});

export const getStockBrandOwners = createAsyncThunk('stock/getStockBrandOwners', async (brandOwnerRequestPayload: any) => {
  const response = await traderOwnerStockService.getStockBrandOwners(brandOwnerRequestPayload);
  return response.data.Data;
});

export const getStockProductCategories = createAsyncThunk('stock/getStockProductCategories', async (siteCode: string) => {
  const response = await traderOwnerStockService.getStockProductCategories(siteCode);
  return response.data.Data;
});

export const stockSlice = createSlice({
  name: 'stock',
  initialState: {
    data: [],
    requests: {
      getStockBrandOwners: {
        data: [],
        loading: false,
        error: '',
      },
      getStockProductCategories: {
        data: [],
        loading: false,
        error: '',
      },
      getRelatedProducts: {
        data: [],
        loading: false,
        error: '',
      },
    },
    totalResults: 0,
    selectedStockItems: [] as IStockDetailResult[],
    brandOwners: [],
    productCategories: [],
    loading: false,
    error: '',
  },
  reducers: {
    setSelectedStockItems: (state, action) => {
      state.selectedStockItems = action.payload;
    },
    updateStockItem: (state, action) => {
      const index = state.selectedStockItems.findIndex((item: any) => item.ProductId === action.payload.ProductId);
      state.selectedStockItems[index] = action.payload;
    },
    removeSelectedStockItem: (state, action) => {
      const index = state.selectedStockItems.findIndex((item: any) => item.ProductId === action.payload.productId);
      state.selectedStockItems.splice(index, 1);
    },
  },
  extraReducers: builder => {
    builder.addCase(getStock.pending, (state, action) => {
      state.loading = true;
      state.error = '';
    });
    builder.addCase(getStock.fulfilled, (state, action) => {
      if ((action.payload?.ResultCode ?? 0) !== 0) {
        state.loading = false;
        state.error = action.payload.ResultMessage;
        return;
      }

      state.data = action.payload.Data;
      state.totalResults = action.payload.TotalRecords;
      state.loading = false;
    });
    builder.addCase(getStock.rejected, (state, action) => {
      state.loading = false;
      state.error = 'Error occured';
    });

    builder.addCase(getStockBrandOwners.pending, (state, action) => {
      state.requests.getStockBrandOwners.loading = true;
      state.requests.getStockBrandOwners.error = '';
    });
    builder.addCase(getStockBrandOwners.fulfilled, (state, action) => {
      if ((action.payload?.ResultCode ?? 0) !== 0) {
        state.loading = false;
        state.error = action.payload.ResultMessage;
        return;
      }

      state.requests.getStockBrandOwners.loading = false;
      state.requests.getStockBrandOwners.data = action.payload;
    });
    builder.addCase(getStockBrandOwners.rejected, (state, action) => {
      state.requests.getStockBrandOwners.loading = false;
      state.requests.getStockBrandOwners.error = 'Error occured';
    });

    builder.addCase(getStockProductCategories.pending, (state, action) => {
      state.requests.getStockProductCategories.loading = true;
      state.requests.getStockProductCategories.error = '';
    });
    builder.addCase(getStockProductCategories.fulfilled, (state, action) => {
      if ((action.payload?.ResultCode ?? 0) !== 0) {
        state.loading = false;
        state.error = action.payload.ResultMessage;
        return;
      }

      state.requests.getStockProductCategories.loading = false;
      state.requests.getStockProductCategories.data = action.payload;
    });
    builder.addCase(getStockProductCategories.rejected, (state, action) => {
      state.requests.getStockProductCategories.loading = false;
      state.requests.getStockProductCategories.error = 'Error occured';
    });

    builder.addCase(getRelatedProducts.pending, (state, action) => {
      state.requests.getRelatedProducts.loading = true;
      state.requests.getRelatedProducts.error = '';
    });
    builder.addCase(getRelatedProducts.fulfilled, (state, action) => {
      if ((action.payload?.ResultCode ?? 0) !== 0) {
        state.loading = false;
        state.error = action.payload.ResultMessage;
        return;
      }

      state.requests.getRelatedProducts.loading = false;
      state.requests.getRelatedProducts.data = action.payload;
    });
    builder.addCase(getRelatedProducts.rejected, (state, action) => {
      state.requests.getRelatedProducts.loading = false;
      state.requests.getRelatedProducts.error = 'Error occured';
    });
  },
});
export const { setSelectedStockItems, updateStockItem, removeSelectedStockItem } = stockSlice.actions;
export default stockSlice.reducer;
