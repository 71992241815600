import React from 'react';
import { ExtendedRouteObject } from '@/types';
import ROUTE_CONSTANTS from '@/routes/route-constants';
import { Manufacturers, System, StagedShoppers, Leads, LeadForm, DCRMSupportTool, Samples } from '@/routes/lazy-component-imports';

const MiscellaneousRoutesObject: ExtendedRouteObject[] = [
  {
    path: ROUTE_CONSTANTS.SAMPLES,
    element: <Samples />,
    params: {
      title: 'Samples',
    },
  },
  {
    path: ROUTE_CONSTANTS.MANUFACTURERS,
    element: <Manufacturers />,
    params: {
      title: 'Manufacturers',
    },
  },
  {
    path: ROUTE_CONSTANTS.SYSTEM,
    element: <System />,
    params: {
      title: 'System',
    },
  },
  {
    path: ROUTE_CONSTANTS.STAGE_SHOPPERS,
    element: <StagedShoppers />,
    params: {
      title: 'Shoppers',
      translationId: 'Portal.Shoppers',
    },
  },
  {
    path: ROUTE_CONSTANTS.LEADS,
    element: (
      <>
        <div className='innermodalcontents widget' style={{ marginTop: 0, backgroundColor: 'white', marginBottom: '8em' }}>
          <Leads />
        </div>
      </>
    ),
    params: {
      title: 'Leads',
    },
  },
  {
    path: ROUTE_CONSTANTS.LEADS_ADD,
    element: (
      <>
        <div className='innermodalcontents widget' style={{ marginTop: 0, backgroundColor: 'white', marginBottom: '8em' }}>
          <LeadForm />
        </div>
      </>
    ),
    params: {
      title: 'Add Lead',
    },
  },
  {
    path: ROUTE_CONSTANTS.LEADS_VIEW,
    element: (
      <>
        <div className='innermodalcontents widget' style={{ marginTop: 0, backgroundColor: 'white', marginBottom: '8em' }}>
          <Leads />
        </div>
      </>
    ),
    params: {
      title: 'View Lead',
    },
  },
  {
    path: ROUTE_CONSTANTS.DYNAMICS_CRM,
    element: <DCRMSupportTool />,
    params: {
      title: 'Dynamics CRM Support Tool',
    },
  },
];
export default MiscellaneousRoutesObject;
