import React from 'react';
import { RouteObject, Navigate } from 'react-router-dom';
import ROUTE_CONSTANTS from '@/routes/route-constants';

// Pages
import {
  OutletsPage,
  ViewOutletWrapper,
  OutletDetailPage,
  CashUpItemReportPage,
  StockOnHandReportPage,
  StockTakePage,
  AddStockPage,
  NotFoundPage,
  CookiePolicyPage,
  TermsAndConditionsPage,
  FrequentlyAskedQuestionsPage,
} from '@/routes/lazy-component-imports';

const SizaminaRoutes: RouteObject[] = [
  {
    path: ROUTE_CONSTANTS.SIZAMINA.ROOT,
    element: <Navigate to={ROUTE_CONSTANTS.SIZAMINA.OUTLETS} />,
  },
  {
    path: ROUTE_CONSTANTS.SIZAMINA.OUTLETS,
    element: <ViewOutletWrapper />,
    children: [
      {
        path: '',
        element: <OutletsPage />,
      },
      {
        path: ':siteCode',
        children: [
          {
            path: '',
            element: <OutletDetailPage />,
          },
          {
            path: ROUTE_CONSTANTS.SIZAMINA.CASHUPITEM,
            element: <CashUpItemReportPage />,
          },
          {
            path: ROUTE_CONSTANTS.SIZAMINA.STOCKINHAND,
            element: <StockOnHandReportPage />,
          },
          {
            path: ROUTE_CONSTANTS.SIZAMINA.STOCKTAKE,
            element: <StockTakePage />,
          },
          {
            path: ROUTE_CONSTANTS.SIZAMINA.STOCKADD,
            element: <AddStockPage />,
          },
        ],
      },
    ],
  },
  {
    path: ROUTE_CONSTANTS.SIZAMINA.FREQUENTLYASKEDQUESTIONS,
    element: <FrequentlyAskedQuestionsPage />,
  },
  {
    path: ROUTE_CONSTANTS.SIZAMINA.COOKIEPOLICY,
    element: <CookiePolicyPage />,
  },
  {
    path: ROUTE_CONSTANTS.SIZAMINA.TERMSANDCONDITIONS,
    element: <TermsAndConditionsPage />,
  },
  {
    path: '*',
    element: <NotFoundPage />,
  },
];

export default SizaminaRoutes;
