// Required React Imports
import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { createTheme, StyledEngineProvider, ThemeProvider } from '@mui/material/styles';

// Redux Imports
import { Provider } from 'react-redux';
import { store } from './Store/Store';
import { default as wireUser } from './Store/watchers/user';

// React Router Imports
import { RouterProvider, Outlet } from 'react-router-dom';
import router from '@/routes';
import { IntlProvider } from 'react-intl';

import messages_en from '@/Translations/en-ZA.json'; // English
import messages_pt from '@/Translations/pt-BR.json'; // Portuguese
import messages_nl from '@/Translations/nl-NL.json'; // Dutch
import messages_es from '@/Translations/es-ES.json'; // Spanish

// Other Imports
import * as serviceWorker from './serviceWorker';
import AppLoader from './appshell/AppLoader';
import ErrorBoundary from './ErrorBoundary';
const messages = {
  'en-ZA': messages_en, // english south africa
  'pt-BR': messages_pt, // portuguese brazil
  'nl-NL': messages_nl, // dutch netherlands
  'es-ES': messages_es,
};
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);
const RootApp = () => {
  const formatSettings = {
    number: {
      roundcurrency: {
        style: 'currency',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
        currency: 'ZAR', // TODO: Fetch from solution.settings
      },
      localcurrency: {
        style: 'currency',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        currency: 'ZAR', // TODO: Fetch from solution.settings
      },
    },
  };

  const theme = createTheme({
    components: {
      MuiPopover: {
        defaultProps: {
          container: rootElement,
        },
      },
      MuiPopper: {
        defaultProps: {
          container: rootElement,
        },
      },
    },
  });

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <IntlProvider locale={'en-ZA'} defaultLocale={'en-ZA'} formats={formatSettings} defaultFormats={formatSettings} messages={messages['en-ZA']}>
          <Suspense fallback={<AppLoader />}>
            <Provider store={store}>
              <RouterProvider router={router} basename='/'>
                <Outlet />
              </RouterProvider>
            </Provider>
          </Suspense>
        </IntlProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

root.render(
  <ErrorBoundary>
    <RootApp />
  </ErrorBoundary>
);

wireUser(store);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
