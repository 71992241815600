import { API } from '@/OutletOwnerApp/constants/api.constants';
import IResponse from '@/OutletOwnerApp/interfaces/response.interface';
import httpService from '@/OutletOwnerApp/services/http.service';
import { getSessionToken } from '@/Identity/access';

import { AxiosPromise, AxiosRequestConfig } from 'axios';
import { IBrandOwnerPayload, IStockTakePayload } from '@/OutletOwnerApp/interfaces/stockDetail.interface';

const traderOwnerStockService = {
  /* STOCK ON HAND REPORT */

  getStock(siteCode: string | undefined): IResponse {
    return httpService.UseHttp('POST', API.TRADER_OWNER_STOCK.STOCK, {
      SessionToken: getSessionToken(),
      SiteCode: siteCode,
    });
  },

  getRelatedStock(siteCode: string, productId: number): IResponse {
    return httpService.UseHttp('POST', API.TRADER_OWNER_STOCK.RELATEDPRODUCTS, {
      SessionToken: getSessionToken(),
      SiteCode: siteCode,
      ProductID: productId
    });
  },

  getRelatedProducts(requestPayload: any): AxiosPromise<any> {
    const payload: AxiosRequestConfig = {
      method: 'POST',
      url: API.TRADER_OWNER_STOCK.RELATEDPRODUCTS,
      data: {
        SessionToken: getSessionToken(),
        SiteCode: requestPayload.siteCode,
        ProductID: requestPayload.productID,
      },
    };

    return httpService.$request(payload);
  },

  getStockPaged(requestPayload: IStockTakePayload): AxiosPromise<any> {
    const payload: AxiosRequestConfig = {
      method: 'POST',
      url: API.TRADER_OWNER_STOCK.STOCK,
      data: {
        SessionToken: getSessionToken(),
        SiteCode: requestPayload.siteCode,
        barCode: requestPayload.barCode,
        shortCode: requestPayload.shortCode,
        name: requestPayload.name,
        PageStart: requestPayload.page,
        PageLimit: requestPayload.records,
        brandOwnerId: requestPayload.brandOwnerId,
        categoryId: requestPayload.categoryId,
      },
    };

    return httpService.$request(payload);
  },

  getStockBrandOwners(requestPayload: IBrandOwnerPayload): AxiosPromise<any> {
    const payload: AxiosRequestConfig = {
      method: 'POST',
      url: API.BRAND_OWNERS,
      headers: {
        'Authorization': getSessionToken()
      },
      data: {
        SessionToken: getSessionToken(),
        SiteCode: requestPayload.siteCode,
        name: requestPayload.name,
      },
    };

    return httpService.$request(payload);
  },

  getStockProductCategories(siteCode: string): any {
    const payload: AxiosRequestConfig = {
      method: 'POST',
      url: API.PRODUCT_CATEGORIES,
      data: {
        SessionToken: getSessionToken(),
        SiteCode: siteCode,
      },
    };

    return httpService.$request(payload);
  },

  submitStockTake(body: any): IResponse {
    return httpService.UseHttp('POST', API.TRADER_OWNER_STOCK.STOCKTAKE, {
      SessionToken: getSessionToken(),
      ...body,
    });
  },
  submitStockUpdate(body: any): IResponse {
    return httpService.UseHttp('POST', API.TRADER_OWNER_STOCK.ADDSTOCK, {
      SessionToken: getSessionToken(),
      ...body,
    });
  },
};

export default traderOwnerStockService;
