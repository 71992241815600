import { lazy } from 'react';

export const OutletsPage = lazy(() => import('@/OutletOwnerApp/components/pages/outlets.page'));
export const ViewOutletWrapper = lazy(() => import('@/appshell/OutletWrapper'));
export const OutletDetailPage = lazy(() => import('@/OutletOwnerApp/components/pages/outlet.page'));
export const CashUpItemReportPage = lazy(() => import('@/OutletOwnerApp/components/pages/cashUpItemReport.page'));
export const StockOnHandReportPage = lazy(() => import('@/OutletOwnerApp/components/pages/stockOnHandReport.page'));
export const StockTakePage = lazy(() => import('@/OutletOwnerApp/components/pages/stockTake.page'));
export const AddStockPage = lazy(() => import('@/OutletOwnerApp/components/pages/addStock.page'));
export const NotFoundPage = lazy(() => import('@/OutletOwnerApp/components/pages/notFound.page'));
export const FrequentlyAskedQuestionsPage = lazy(() => import('@/OutletOwnerApp/components/pages/frequentlyAskedQuestions.page'));
export const CookiePolicyPage = lazy(() => import('@/OutletOwnerApp/components/pages/cookiePolicy.page'));
export const TermsAndConditionsPage = lazy(() => import('@/OutletOwnerApp/components/pages/termsAndConditions.page'));
