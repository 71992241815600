import React from 'react';
import { ExtendedRouteObject } from '@/types';
import ROUTE_CONSTANTS from '@/routes/route-constants';
import {
  DeviceList,
  BulkDevices,
  ViewDevice,
  CardList,
  ShoppersPage,
  ShopperPage,
  SyncProfiles,
  CreateSyncProfile,
  ViewSyncProfile,
} from '@/routes/lazy-component-imports';

const PeopleAndDevicesRouterObject: ExtendedRouteObject[] = [
  {
    path: ROUTE_CONSTANTS.DEVICES,
    element: <DeviceList />,
    params: { title: 'Devices' },
  },
  {
    path: ROUTE_CONSTANTS.BULK_DEVICES,
    element: <BulkDevices />,
    params: { title: 'Devices' },
  },
  {
    path: ROUTE_CONSTANTS.VIEW_DEVICE,
    element: <ViewDevice />,
    params: { title: 'View Device' },
  },
  {
    path: ROUTE_CONSTANTS.CARDS,
    element: <CardList />,
    params: {
      title: 'Cards',
      translationId: 'Portal.Cards',
    },
  },
  {
    path: ROUTE_CONSTANTS.SHOPPERS,
    element: <ShoppersPage defaultSubType='Shopper' />,
    params: { title: 'Shoppers' },
  },
  {
    path: ROUTE_CONSTANTS.SHOPPER,
    element: <ShopperPage />,
    params: { title: 'Shopper' },
  },
  {
    path: ROUTE_CONSTANTS.SYNC_PROFILES,
    element: <SyncProfiles />,
    params: { title: 'Devices' },
  },
  {
    path: ROUTE_CONSTANTS.CREATE_SYNC_PROFILE,
    element: <CreateSyncProfile />,
    params: { title: 'Devices' },
  },
  {
    path: ROUTE_CONSTANTS.VIEW_SYNC_PROFILE,
    element: <ViewSyncProfile />,
    params: { title: 'Devices' },
  },
];

export default PeopleAndDevicesRouterObject;
