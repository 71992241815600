import DASHBOARD_CONSTANTS from '@/routes/route-constants/portal/dashboard-constants';
import PEOPLE_AND_DEVICES_CONSTANTS from '@/routes/route-constants/portal/people-and-devices-constants';
import COMPANIES_CONSTANTS from './portal/companies-constants';
import PROMOTIONS_CONSTANTS from './portal/promotion-constants';
import PRODUCTS_CONSTANTS from './portal/products-constants';
import CORRESPONDENCE_CONSTANTS from './portal/correspondence-constants';
import BATCH_SMS_CONSTANTS from './portal/batch-sms-constants';
import ACCOUNTS_CONSTANTS from './portal/accounts-constants';
import SOLUTIONS_CONSTANTS from './portal/solutions-constants';
import APP_PACKAGES_CONSTANTS from './portal/app-packages-constants';
import REPORTS_CONSTANTS from './portal/reports-constants';
import TRADER_CONSTANTS from './portal/trader-constants';
import SIZAMINA_ROUTE_CONSTANTS from './sizamina/sizamina-constants';

const ROUTE_CONSTANTS = {
  APP: '/',
  OUTLET_SIGN_IN: '/OutletSignIn',
  PORTAL: '/portal',
  TOUCHSIDES_SIGN_IN: '/SignIn',
  TOUCHSIDES_RESET_PASSWORD: '/ResetPassword',
  TOUCHSIDES_CHANGE_PASSWORD: '/ChangePasswordWithToken',
  LEADS: 'Leads',
  LEADS_VIEW: 'Leads/:leadId',
  LEADS_ADD: 'Add/Lead',
  DYNAMICS_CRM: 'DynamicsCRM/SupportTool',
  SYSTEM: 'System',
  STAGE_SHOPPERS: 'Stage/Shoppers',
  REDIRECTOR: 'Redirect/:time',
  MANUFACTURERS: 'Manufacturers',
  SAMPLES: 'Samples',
  ...DASHBOARD_CONSTANTS,
  ...PEOPLE_AND_DEVICES_CONSTANTS,
  ...COMPANIES_CONSTANTS,
  ...PROMOTIONS_CONSTANTS,
  ...PRODUCTS_CONSTANTS,
  ...CORRESPONDENCE_CONSTANTS,
  ...BATCH_SMS_CONSTANTS,
  ...ACCOUNTS_CONSTANTS,
  ...SOLUTIONS_CONSTANTS,
  ...APP_PACKAGES_CONSTANTS,
  ...REPORTS_CONSTANTS,
  ...TRADER_CONSTANTS,
  SIZAMINA: {
    ...SIZAMINA_ROUTE_CONSTANTS,
  },
};

export default ROUTE_CONSTANTS;
