import React, { Component } from 'react';

export default class LegalBanner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bannerDismissed: true,
    };
  }

  componentDidMount() {
    this.hasBannerBeenDismissed();
  }

  hasBannerBeenDismissed = () => {
    if (localStorage.getItem('cookieBanner') === 'dismissed') {
      this.setState({ bannerDismissed: true });
    } else {
      this.setState({ bannerDismissed: false });
    }
  };

  dismissBanner = () => {
    localStorage.setItem('cookieBanner', 'dismissed');
    this.setState({ bannerDismissed: true });
    console.log('legal banner dismissed');
  };

  render() {
    if (this.state.bannerDismissed === false) {
      return (
        <div className='legal-banner'>
          <span>
          By using this website, you agree to the website {' '}
            <a href='/terms-and-conditions' target='_blank' rel='noopener noreferrer' className='primary-link'>
              Terms and Conditions
            </a>{' '}
          and{' '}
            <a href='/cookie-policy' target='_blank' rel='noopener noreferrer' className='primary-link'>
              Cookie Policy
            </a>{' '}
          and you acknowledge that they apply to you.
          </span>

          <span className='legal-banner-close-button' onClick={this.dismissBanner}>
            &times;
          </span>
        </div>
      );
    } else {
      return null;
    }
  }
}
