import React from 'react';

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, errorMessage: null, errorStack: null, errorComponentStack: null };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true, errorMessage: error.message, errorStack: error.stack, errorComponentStack: info.componentStack });
    // TODO: log the error to an error reporting service
    // logErrorToMyService(error, info);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className='error'>
          <h1>Error</h1>
          <div style={{ color: 'red', fontSize: '16px', clear: 'both' }}>{this.state.errorMessage}</div>
        </div>
      );
    }
    return this.props.children;
  }
}
