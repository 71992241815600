import Dexie from 'dexie';
import moment from 'moment-timezone';
import { findIana } from 'windows-iana';
import AxiosClient from '../Helpers/AxiosClient';
import Country from './Country';
import { IEnvironment, environments } from './Environments';
import { ISolutionSettings, IPermission, IUser, ISolutionRecord, IEndpointUrls, IEnvironmentRecord, ISessionRecord } from '@/types';

// ConfigurationManager is designed to fetch all the necessary configuration for environment/solutions so users can quickly log in
// All retrieved values are cached in IndexedDB to minimise requests but also to assist with Azure AD redirects, page refreshes and new tabs
class ConfigurationDatabase extends Dexie {
  environments!: Dexie.Table<IEnvironmentRecord, string>;
  sessions!: Dexie.Table<ISessionRecord, string>;
  solutions!: Dexie.Table<ISolutionRecord, string>;
  current!: Dexie.Table<ISessionRecord, number>;

  constructor() {
    super('configuration_db_v1');

    this.version(1).stores({
      environments: 'name', // environment/platform settings like endpoints
      solutions: 'solution', // solution specific settings like country/currency etc
      current: 'key', // keeps track of the users current environment/solution/session
    });
  }
}

const configurationDatabase = new ConfigurationDatabase();

// need to store sessions with solution+userId as you could log into solutions with different users etc
// should just reuse token if user session has at least ~1hr ttl left, should delete old tokens
// current should point to solution+userId
// db.sessions.where('[solution+userId]').equals([2,3])

const http = window.location.protocol === 'http:';

const getEndpoints = (env: IEnvironment): Promise<IEndpointUrls> => {
  const configurationClient = new AxiosClient(env.configurationService);

  const dashboardGateway = configurationClient
    .post('/GetSetting', {
      solutionName: 'Touch Platform',
      groupKey: 'Tsp.Asterix.Dashboard.Rest.Gateway',
      settingKey: 'ApiUrl',
    })
    .then(result => (result.data.ResultCode !== 0 ? Promise.reject(new Error(`Error getting endpoints: ${result.data.ResultMessage}`)) : result))
    .then(res => {
      return {
        Dashboard: http ? res.data.Value : res.data.Value.replace('http:', 'https:'),
      };
    });

  const cloudGateway = configurationClient
    .post('/GetSetting', {
      solutionName: 'Touch Platform',
      groupKey: 'Tsp.Asterix.CloudFunctions',
      settingKey: 'Html2PdfEndpointAddress',
    })
    .then(result => (result.data.ResultCode !== 0 ? Promise.reject(new Error(`Error getting endpoints: ${result.data.ResultMessage}`)) : result))
    .then(res => {
      return {
        Html2Pdf: http ? res.data.Value : res.data.Value.replace('http:', 'https:'),
      };
    });

  return Promise.all([dashboardGateway, cloudGateway]).then(values => {
    const reducedEndpoints = values.reduce((acc, cur) => ({ ...acc, ...cur }), {});

    const aspGatewayEndpoints = {
      AsterixCompany: env.apiEndpointPrefix + 'V4/AsterixCompany',
      Company: env.apiEndpointPrefix + 'V4/AsterixCompany',
      AsterixGeneral: env.apiEndpointPrefix + 'V4/General',
      HashExceptions: env.apiEndpointPrefix + 'V4/HashExceptions',
      BrandOwner: env.apiEndpointPrefix + 'V4/BrandOwner',
      BulkManagement: env.apiEndpointPrefix + 'V4/BulkManagement',
      BulkSms: env.apiEndpointPrefix + 'V4/BulkSms',
      Calendar: env.apiEndpointPrefix + 'V4/Calendar',
      Card: env.apiEndpointPrefix + 'V4/Card',
      CardBlacklist: env.apiEndpointPrefix + 'V4/CardBlacklist',
      Catalog: env.apiEndpointPrefix + 'V4/Catalog',
      CheatSheet: env.apiEndpointPrefix + 'V4/CheatSheet',
      Configuration: env.apiEndpointPrefix + 'V4/Configuration',
      DCRMSupportTool: env.apiEndpointPrefix + 'V4/DCRMSupportTool',
      DataQuery: env.apiEndpointPrefix + 'V4/DataQuery',
      Device: env.apiEndpointPrefix + 'V4/Device',
      DevicePackage: env.apiEndpointPrefix + 'V4/DevicePackage',
      Email: env.apiEndpointPrefix + 'V4/Email',
      EntityProperties: env.apiEndpointPrefix + 'V4/EntityProperties',
      FieldOps: env.apiEndpointPrefix + 'V4/FieldOps',
      FranchiseGroup: env.apiEndpointPrefix + 'V4/FranchiseGroup',
      General: env.apiEndpointPrefix + 'V4/General',
      Group: env.apiEndpointPrefix + 'V4/Group',
      Manufacturer: env.apiEndpointPrefix + 'V4/Manufacturer',
      Operator: env.apiEndpointPrefix + 'V4/Operator',
      PersistentLists: env.apiEndpointPrefix + 'V4/PersistentLists',
      Person: env.apiEndpointPrefix + 'V4/Person',
      Processor: env.apiEndpointPrefix + 'V4/Processor',
      Product: env.apiEndpointPrefix + 'V4/Product',
      ProductClassification: env.apiEndpointPrefix + 'V4/ProductClassification',
      ProductTree: env.apiEndpointPrefix + 'V4/ProductTree',
      Promotion: env.apiEndpointPrefix + 'V4/Promotion',
      RemoteQuery: env.apiEndpointPrefix + 'V4/RemoteQuery',
      RetailProduct: env.apiEndpointPrefix + 'V4/RetailProduct',
      Sales: env.apiEndpointPrefix + 'V4/Sales',
      Security: env.apiEndpointPrefix + 'V4/Security',
      Sessions: env.apiEndpointPrefix + 'V4/Sessions',
      Shopper: env.apiEndpointPrefix + 'V4/Shopper',
      Sms: env.apiEndpointPrefix + 'V4/Sms',
      Subscription: env.apiEndpointPrefix + 'V4/Subscription',
      Supplier: env.apiEndpointPrefix + 'V4/Supplier',
      System: env.apiEndpointPrefix + 'V4/System',
      SystemAsterix: env.apiEndpointPrefix + 'V4/SystemAsterix',
      Tax: env.apiEndpointPrefix + 'V4/Tax',
      Template: env.apiEndpointPrefix + 'V4/Template',
      Trader: env.apiEndpointPrefix + 'V4/Trader',
      TraderAccounts: env.apiEndpointPrefix + 'V4/TraderAccounts',
      TraderOwnerReport: env.apiEndpointPrefix + 'V4/TraderOwnerReport',
      TraderWallet: env.apiEndpointPrefix + 'V4/Wallet',
      Wallet: env.apiEndpointPrefix + 'V4/Wallet',
      WalletAsterix: env.apiEndpointPrefix + 'V4/Wallet',
      Website: env.apiEndpointPrefix + 'V4/Website',
      PromotionRuleBuilder: env.apiEndpointPrefix + 'V4/PromotionRuleBuilder',
    };

    return Object.assign(reducedEndpoints, aspGatewayEndpoints) as IEndpointUrls;
  });
};

const getSolutionDataFor = async (session: ISessionRecord) => {
  return configurationDatabase.solutions.get(session.solution).then(solution => {
    if (!solution) {
      return null;
    }

    return {
      user: session.user,
      solutionName: solution.solution,
      ...solution.settings,
    };
  });
};

const getPermissionsAndSolutionSettings = (endpointUrls: IEndpointUrls, user: IUser, solutionName: string) => {
  const configurationClient = new AxiosClient(endpointUrls.Configuration);
  const asterixGeneralClient = new AxiosClient(endpointUrls.AsterixGeneral);
  const securityClient = new AxiosClient(endpointUrls.Security);

  const isPlatform = solutionName.toUpperCase() === 'TOUCH PLATFORM';

  const Permission = securityClient
    .post('/GetEffectivePermissions', {
      sessionToken: user.SessionToken,
    })
    .then(result => (result.data.ResultCode !== 0 ? Promise.reject(new Error(`Error getting permissions: ${result.data.ResultMessage}`)) : result))
    .then(res => {
      return {
        user: {
          Permissions: res.data.Permissions.map((permission: IPermission) => permission.Key),
          SolutionName: user.SolutionName,
          UserName: user.Name,
          EmailAddress: user.EmailAddress,
          SessionToken: user.SessionToken,
          FullName: user.Name,
          IsPlatformUser: isPlatform,
          SessionTokenTime: new Date(moment().add(8, 'hours').toDate()).toString(),
          Context: user.Context,
        },
      };
    });

  if (isPlatform) {
    const platformSettings: ISolutionSettings = {
      country: 'SouthAfrica',
      locale: 'en-ZA',
      currency: 'ZAR',
      timezone: '', // moment js
      airtimeEnabled: false,
      whitelabel: '',
      isDynamicsCRMSolution: false,
    };
    return Promise.all([Permission])
      .then(values => {
        const reduced = values.reduce((total, num) => ({ ...total, ...num }), platformSettings) as any;

        configurationDatabase.solutions.put({
          solution: solutionName,
          timeSaved: new Date(),
          settings: platformSettings,
        });

        return reduced;
      })
      .catch(x => Promise.reject(x));
  }

  const userType = asterixGeneralClient
    .post('/GetAuthenticatedUserType', {
      sessionToken: user.SessionToken,
    })
    .then(result => (result.data.ResultCode !== 0 ? Promise.reject(new Error(`Error getting settings: ${result.data.ResultMessage}`)) : result))
    .then(res => {
      return {
        userType: res.data.AuthenticatedUserType,
      };
    });

  // Asterix Solution
  const whiteLabel = configurationClient
    .post('/GetSetting', {
      solutionName: user.SolutionName,
      groupKey: 'Tsp.Asterix.Solution',
      settingKey: 'WhiteLabel',
    })
    .then(result => (result.data.ResultCode !== 0 ? Promise.reject(new Error(`Error getting settings: ${result.data.ResultMessage}`)) : result))
    .then(res => {
      return { whitelabel: res.data.Value };
    });

  const solutionType = configurationClient
    .post('/GetSetting', {
      solutionName: user.SolutionName,
      groupKey: 'Tsp.Core.Configuration',
      settingKey: 'Environment',
    })
    .then(result => (result.data.ResultCode !== 0 ? Promise.reject(new Error(`Error getting settings: ${result.data.ResultMessage}`)) : result))
    .then(res => {
      return { solutionType: res.data.Value };
    });

  const airtimeEnabled = configurationClient
    .post('/GetSetting', {
      solutionName: user.SolutionName,
      groupKey: 'Tsp.Asterix.Solution',
      settingKey: 'AirtimeEnabled',
    })
    .then(result => (result.data.ResultCode !== 0 ? Promise.reject(new Error(`Error getting settings: ${result.data.ResultMessage}`)) : result))
    .then(res => {
      return { airtimeEnabled: res.data.Value.toLowerCase() === 'true' };
    });

  const country = configurationClient
    .post('/GetSetting', {
      solutionName: user.SolutionName,
      groupKey: 'Tsp.Asterix.Solution',
      settingKey: 'Country',
    })
    .then(result => (result.data.ResultCode !== 0 ? Promise.reject(new Error(`Error getting settings: ${result.data.ResultMessage}`)) : result))
    .then(res => {
      const countryObj = Country[res.data.Value];
      return {
        country: res.data.Value,
        locale: countryObj.DefaultCulture,
        currency: countryObj.Currency.Code,
      };
    });

  const timeZone = configurationClient
    .post('/GetSetting', {
      solutionName: user.SolutionName,
      groupKey: 'Tsp.Asterix.Solution',
      settingKey: 'TimeZone',
    })
    .then(result => (result.data.ResultCode !== 0 ? Promise.reject(new Error(`Error getting settings: ${result.data.ResultMessage}`)) : result))
    .then(res => {
      return { timezone: findIana(res.data.Value)[0] };
    });

  const isDynamicsCRMSolution = configurationClient
    .post('/GetSetting', {
      solutionName: user.SolutionName,
      groupKey: 'Tsp.Asterix.Solution',
      settingKey: 'IsDynamicsCRMSolution',
    })
    .then(result => (result.data.ResultCode !== 0 ? Promise.reject(new Error(`Error getting settings: ${result.data.ResultMessage}`)) : result))
    .then(res => {
      return { isDynamicsCRMSolution: res.data.Value };
    });

  return Promise.all([Permission, whiteLabel, solutionType, airtimeEnabled, country, timeZone, userType, isDynamicsCRMSolution]).then(values => {
    const reducedValues = values.reduce((total, num) => ({ ...total, ...num }), { solutionName }) as any;

    if ((reducedValues.isDynamicsCRMSolution || '').toLowerCase() === 'true') {
      reducedValues.user.Permissions.push('system://is-dynamics-crm-solution');
    }

    const solutionSettings = { ...reducedValues };
    delete solutionSettings.user;
    configurationDatabase.solutions.put({
      solution: solutionName,
      timeSaved: new Date(),
      settings: solutionSettings,
    });

    return reducedValues;
  });
};

const getEnvironment = (): IEnvironment => {
  return Object.values(environments).find(env => env.urlMatches.find(urlBit => window.location.href.includes(urlBit)) !== undefined) || environments[0];
};

const isDevelopment = () => {
  return getEnvironment().development || false;
};

const endpointsAsync = async () => {
  const environment = getEnvironment();

  const fetchEndpoints = () =>
    getEndpoints(environment).then(endpointUrls => {
      configurationDatabase.environments.put({
        name: environment.name,
        timeSaved: new Date(),
        endpoints: endpointUrls,
      });
      return endpointUrls;
    });

  return configurationDatabase.environments.get(environment.name).then(item => {
    if (item === undefined) {
      return fetchEndpoints();
    }

    // fetch anyway if db results are old, but return immediately from db
    if (new Date().getTime() - item.timeSaved.getTime() > 300 * 1000)
      // max age in ms
      fetchEndpoints();

    return item.endpoints;
  });
};

const currentSessionKey = 1;

const getCurrentSession = () => {
  return configurationDatabase.current.get(currentSessionKey);
};

const setCurrentSession = async (session: ISessionRecord) => {
  if (session === null) {
    await configurationDatabase.current.delete(currentSessionKey).catch(error => {
      console.error('Error: ' + error);
    });
  } else {
    await configurationDatabase.current.put({
      key: currentSessionKey,
      solution: session.solution,
      userId: 1,
      timeSaved: new Date(),
      token: session.token,
      user: session.user,
    });
  }
};

const setSessionData = (session: ISessionRecord) => {
  if (session !== null) {
    return configurationDatabase.sessions.put(session);
  }
  return null;
};

export default {
  endpointsAsync,
  getEnvironment,
  getPermissionsAndSolutionSettings,
  getSolutionDataFor,
  getCurrentSession,
  setCurrentSession,
  setSessionData,
  isDevelopment,
};
