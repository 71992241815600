import { lazy } from 'react';
export const PortalWrapper = lazy(() => import('@/appshell/PortalWrapper'));
export const Redirector = lazy(() => import('@/appshell/Redirector'));
export * from './dashboard';
export * from './people-and-devices';
export * from './companies';
export * from './promotions';
export * from './products';
export * from './correspondence';
export * from './batch-sms';
export * from './accounts';
export * from './solutions';
export * from './app-packages';
export * from './reports';
export * from './traders';
export * from './other';
