const PEOPLE_AND_DEVICES_CONSTANTS = {
  DEVICES: '/portal/Devices',
  CARDS: 'Cards',
  SHOPPERS: 'Shoppers',
  SHOPPER: 'Shoppers/:shopperUid',
  BULK_DEVICES: '/portal/BulkDevices',
  VIEW_DEVICE: 'ViewDevice/:deviceUID',
  SYNC_PROFILES: '/portal/SyncProfiles',
  CREATE_SYNC_PROFILE: 'CreateSyncProfile',
  VIEW_SYNC_PROFILE: 'ViewSyncProfile/:syncProfileUID',
};
export default PEOPLE_AND_DEVICES_CONSTANTS;
