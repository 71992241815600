import React from 'react';
import { ExtendedRouteObject } from '@/types';
import ROUTE_CONSTANTS from './route-constants';
import { PortalWrapper, Redirector } from '@/routes/lazy-component-imports';
import DashboardRouterObject from './route-objects/dashboard';
import PeopleAndDevicesRouterObject from './route-objects/people-and-devices';
import CompaniesRouterObject from './route-objects/companies';
import PromotionsRouteObject from './route-objects/promotions';
import ProductsRouteObject from './route-objects/products';
import CorrespondenceRouteObject from './route-objects/correspondence';
import BatchSMSRouteObject from './route-objects/batch-sms';
import AccountsRouteObject from './route-objects/accounts';
import SolutionsRouteObject from './route-objects/solutions';
import AppPackageRouteObject from './route-objects/app-package';
import ReportsRouteObject from './route-objects/reports';
import TradersRouteObject from './route-objects/traders';
import MiscellaneousRoutesObject from './route-objects/miscellaneous';
import Welcome from '@/Portal/Welcome';

const PortalRoutes: ExtendedRouteObject[] = [
  {
    path: ROUTE_CONSTANTS.PORTAL,
    element: <PortalWrapper />,
    params: {
      title: 'Portal Wrapper',
    },
    children: [
      {
        path: '',
        index: true,
        element: <Redirector />,
      },
      {
        path: 'Welcome',
        index: true,
        element: <Welcome />,
      },
      ...DashboardRouterObject,
      ...PeopleAndDevicesRouterObject,
      ...CompaniesRouterObject,
      ...PromotionsRouteObject,
      ...ProductsRouteObject,
      ...CorrespondenceRouteObject,
      ...BatchSMSRouteObject,
      ...AccountsRouteObject,
      ...SolutionsRouteObject,
      ...AppPackageRouteObject,
      ...ReportsRouteObject,
      ...TradersRouteObject,
      ...MiscellaneousRoutesObject,
    ],
  },
];

export default PortalRoutes;
