import React from 'react';

const Welcome = () => {
  return (
    <>
      <p>If you have reached this page, it may indicate that no permissions have been assigned to you.</p>
      <p>Please contact a system administrator.</p>
    </>
  );
};

export default Welcome;
