import { createSelector, createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const initialState = {
  touchsidesCatalogUID: null,
};

export const selectTouchsidesCatalogUID = createSelector(
  state => state.catalog.touchsidesCatalogUID,
  touchsidesCatalogUID => touchsidesCatalogUID
);

export const catalogSlice = createSlice({
  name: 'catalog',
  initialState,
  reducers: {
    setTouchsidesCatalogUID: (state, action) => {
      state.touchsidesCatalogUID = action.payload;
    },
  },
});

export const { setTouchsidesCatalogUID } = catalogSlice.actions;

export default catalogSlice.reducer;
