import React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';

const PortalProgress = styled(LinearProgress)(({ theme }) => ({
  backgroundColor: '#efefef',
  '& .MuiLinearProgress-bar': {
    backgroundColor: '#ffa300',
  },
}));

const Loading = () => {
  return <PortalProgress variant='indeterminate' />;
};

export default Loading;
