import { lazy } from 'react';

export const Promotions = lazy(() => import('@/Portal/Promotions/PromotionList'));
export const PromotionUpload = lazy(() => import('@/Portal/Promotions/PromotionUpload'));
export const Promotion = lazy(() => import('@/Portal/Promotions/ManagePromotion'));
export const PromotionAdd = lazy(() => import('@/Portal/Promotions/AddPromotion'));
export const PromotionCalendar = lazy(() => import('@/Portal/Promotions/PromotionCalendar'));
export const PromotionsSpecialDates = lazy(() => import('@/Portal/Promotions/PromotionsSpecialDates'));
export const PromotionWizard = lazy(() => import('@/Portal/Promotions/RuleBuilder/PromotionWizard'));
export const RuleComponent = lazy(() => import('@/Portal/Promotions/RuleComponent'));
export const VoucherManager = lazy(() => import('@/Portal/Vouchers/VoucherManager'));
export const VoucherBook = lazy(() => import('@/Portal/Vouchers/VoucherBook'));
