import { createSelector, createSlice } from '@reduxjs/toolkit';
import { ISolutionReduxState } from '@/types';
import { RootState } from './Store';

const initialState: ISolutionReduxState = {
  user: {
    Permissions: [],
    SolutionName: '',
    UserName: '',
    SessionToken: '',
    FullName: '',
    IsPlatformUser: false,
    SessionTokenTime: '',
  },
  settings: {
    solutionName: '',
    whitelabel: '',
    solutionType: '',
    airtimeEnabled: false,
    country: '',
    locale: 'en-ZA',
    currency: 'ZAR',
    timezone: '',
    userType: '',
    isDynamicsCRMSolution: false,
  },
  name: '',
};

export const solutionSlice = createSlice({
  name: 'solution',
  initialState,
  reducers: {
    setSolution: (state, action) => {
      state.user = action.payload.user;
      state.settings = action.payload.settings;
    },
    setUser: (state, action) => {
      state.user = action.payload.user;
    },
  },
});

export const selectSolution = (state: RootState) => state.solution;
export const solutionSelector = createSelector(selectSolution, solution => solution);
export const userSelector = createSelector(selectSolution, solution => solution.user);

export const { setSolution, setUser } = solutionSlice.actions;

export default solutionSlice.reducer;
