export const API = {
  BRAND_OWNERS: '/V4/BrandOwner/Search',
  DEVICES: '/V4/Device/GetTraderDevices',
  HEALTH_FILE: '/health/file',
  PRODUCT_CATEGORIES: '/V4/ProductCategory/Search',
  TRADERS: '/V4/Trader/GetSessionTraders',
  TRADER_OWNER_REPORT: {
    DEVICES: '/V4/TraderOwnerReport/GetDevices',
    CASHUP_ITEM_DETAIL: '/V4/TraderOwnerReport/GetDetailedCashUpInfo',
    CASHUP_ITEM_REPORT: '/V4/TraderOwnerReport/GetCashUp',
    CASHUP_ITEM_SUMMARY: '/V4/TraderOwnerReport/GetSummaryCashUpInfo',
    OPERATORS: '/V4/TraderOwnerReport/GetOperators',
    STOCKONHAND_DETAIL: '/V4/TraderOwnerReport/GetDetailedStockOnHandInfo',
    STOCKONHAND_REPORT: '/V4/TraderOwnerReport/GetStockOnHand',
    STOCKONHAND_SUMMARY: '/V4/TraderOwnerReport/GetSummaryStockOnHandInfo',
    TENDER_TYPES: '/V4/TraderOwnerReport/GetTenderTypes'
  },
  TRADER_OWNER_STOCK: {
    ADDSTOCK: '/V4/TraderOwnerStock/StockUpdate',
    STOCK: '/V4/TraderOwnerStock/GetFilteredStock',
    STOCKTAKE: '/V4/TraderOwnerStock/StockTake',
    RELATEDPRODUCTS: '/V4/TraderOwnerStock/GetRelatedProducts'
  },
};
