import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import auditReducer from './AuditSlice';
import catalogReducer from './CatalogSlice';
import endpointsReducer from './EndpointsSlice';
import solutionReducer from './SolutionSlice';
import stockReducer from '@/OutletOwnerApp/features/stock/stockSlice';

export const store = configureStore({
  reducer: {
    audit: auditReducer,
    catalog: catalogReducer,
    endpoints: endpointsReducer,
    solution: solutionReducer,
    stock: stockReducer,
  },
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
