import { lazy } from 'react';

// Brands
export const BrandOwners = lazy(() => import('@/Portal/BrandOwners/BrandOwners'));
export const BrandOwner = lazy(() => import('@/Portal/BrandOwners/BrandOwner'));
export const ViewBrand = lazy(() => import('@/Portal/BrandOwners/ViewBrand'));

// Companies
export const Companies = lazy(() => import('@/Portal/Companies/Companies'));
export const CompanyProfile = lazy(() => import('@/Portal/Companies/CompanyProfile'));

// Franchise Groups
export const FranchiseGroups = lazy(() => import('@/Portal/FranchiseGroups/FranchiseGroups'));
export const Manufacturers = lazy(() => import('@/Portal/Manufacturers/Manufacturers'));

//Entity Tags
export const EntityTags = lazy(() => import('@/Portal/EntityTags'));

//Trader Groups
export const TraderGroups = lazy(() => import('@/Portal/TraderGroups/TraderGroups'));

//Traders
export const Traders = lazy(() => import('@/Portal/Traders/Traders'));
export const TraderProfile = lazy(() => import('@/Portal/Traders/TraderProfile/TraderProfile'));
