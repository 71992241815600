import React from 'react';
import { Navigate } from 'react-router';
import { ExtendedRouteObject } from '@/types';
import ROUTE_CONSTANTS from '@/routes/route-constants';
import { DashboardHighlights, DashboardPromotionList, DashboardTraderList, DashboardDemo } from '@/routes/lazy-component-imports';

const DashboardRouterObject: ExtendedRouteObject[] = [
  {
    path: ROUTE_CONSTANTS.DASHBOARD,
    element: <Navigate to={ROUTE_CONSTANTS.DASHBOARD_HIGHLIGHTS} replace={true} />,
  },
  {
    path: ROUTE_CONSTANTS.DASHBOARD_HIGHLIGHTS,
    element: <DashboardHighlights />,
    params: {
      title: 'Highlights',
      permission: 'system://session',
    },
  },
  {
    path: ROUTE_CONSTANTS.DASHBOARD_PROMOTIONS,
    element: <DashboardPromotionList />,
    params: {
      title: 'Promotions',
      translationId: 'Portal.Promotions',
    },
  },
  {
    path: ROUTE_CONSTANTS.DASHBOARD_TRADERS,
    element: <DashboardTraderList />,
    // TODO: Use either liquor or trader list component if solution name includes 'liquor'
    // this.props.solutionName.toLowerCase().includes('liquor') ? (
    //   <PageContents title='Traders'>
    //     <DashboardTraderListLiquor {...this.props} />
    //   </PageContents>
    // ) : (
    //   <PageContents title='Traders'>
    //     <DashboardTraderList {...this.props} />
    //   </PageContents>
    // );
    // if (this.props.covid19) {
    //   const CovidPage = () => (
    //     <PageContents title='COVID-19'>
    //       <a href='https://sacoronavirus.co.za'>coronavirussa.co.za</a>
    //     </PageContents>
    //   );
    //   DashboardHighlightsPage = CovidPage;
    //   DashboardPromotionsPage = CovidPage;
    //   DashboardTradersPage = CovidPage;
    // }
    params: {
      title: 'Traders',
    },
  },
  {
    path: ROUTE_CONSTANTS.DASHBOARD_DEMO,
    element: <DashboardDemo />,
    params: {
      title: 'Demo',
    },
  },
];

export default DashboardRouterObject;
