import React from 'react';
import { ExtendedRouteObject } from '@/types';
import ROUTE_CONSTANTS from '@/routes/route-constants';

import { ViewDevicePackage, DevicePackages, ViewDevicePackageGroup, DevicePackageGroups } from '@/routes/lazy-component-imports';

const AppPackageRouteObject: ExtendedRouteObject[] = [
  {
    path: ROUTE_CONSTANTS.CREATE_PACKAGE,
    element: <ViewDevicePackage />,
    params: {
      title: 'Create Package',
    },
  },
  {
    path: ROUTE_CONSTANTS.PACKAGES,
    element: <DevicePackages />,
    params: {
      title: 'Packages',
    },
  },
  {
    path: ROUTE_CONSTANTS.VIEW_PACKAGE,
    element: <ViewDevicePackage />,
    params: {
      title: 'Package',
    },
  },
  {
    path: ROUTE_CONSTANTS.CREATE_PACKAGE_GROUP,
    element: <ViewDevicePackageGroup />,
    params: {
      title: 'Create Package Group',
    },
  },
  {
    path: ROUTE_CONSTANTS.PACKAGE_GROUPS,
    element: <DevicePackageGroups />,
    params: {
      title: 'Device Package Groups',
    },
  },
  {
    path: ROUTE_CONSTANTS.VIEW_PACKAGE_GROUP,
    element: <ViewDevicePackageGroup />,
    params: {
      title: 'Package Group',
    },
  },
];
export default AppPackageRouteObject;
