import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  active: false,
  entityKey: null,
  entityName: null,
  propertyName: null,
};

export const auditSlice = createSlice({
  name: 'audit',
  initialState,
  reducers: {
    setAudit: (state, action) => {
      state.active = action.payload.active == undefined ? true : action.payload.active;
      state.entityKey = action.payload.entityKey;
      state.entityName = action.payload.entityName;
      state.propertyName = action.payload.propertyName;
    },
  },
});

export const { setAudit } = auditSlice.actions;

export default auditSlice.reducer;
