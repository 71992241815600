import React from 'react';
import { ExtendedRouteObject } from '@/types';
import ROUTE_CONSTANTS from '@/routes/route-constants';

import {
  Promotions,
  PromotionUpload,
  Promotion,
  PromotionAdd,
  PromotionCalendar,
  PromotionsSpecialDates,
  PromotionWizard,
  RuleComponent,
  VoucherManager,
  VoucherBook,
} from '@/routes/lazy-component-imports';

const PromotionsRouteObject: ExtendedRouteObject[] = [
  {
    path: ROUTE_CONSTANTS.PROMOTIONS,
    element: <Promotions />,
    params: {
      title: 'Promotions',
      translationId: 'Portal.Promotions',
    },
  },
  {
    path: ROUTE_CONSTANTS.PROMOTION_UPLOAD,
    element: <PromotionUpload />,
    params: {
      title: 'Promotion/Rule Bulk Upload',
    },
  },
  {
    path: ROUTE_CONSTANTS.PROMOTION_ADD,
    element: <PromotionAdd />,
    params: {
      title: 'Add Promotion',
    },
  },
  {
    path: ROUTE_CONSTANTS.VIEW_PROMOTION,
    element: <Promotion />,
    params: {
      title: 'Promotion',
    },
  },
  {
    path: ROUTE_CONSTANTS.CREATE_PROMO_RULE,
    element: <RuleComponent />,
  },
  {
    path: ROUTE_CONSTANTS.VIEW_PROMO_RULE,
    element: <RuleComponent />,
  },
  {
    path: ROUTE_CONSTANTS.RULE_BUILDER_CREATE_RULE,
    element: <PromotionWizard />,
    loader: () => ({
      mode: 'editing'
    }),
    params: {
      title: 'Build Rule:',
    },
  },
  {
    path: ROUTE_CONSTANTS.RULE_BUILDER_VIEW_RULE,
    element: <PromotionWizard />,
    loader: () => ({
      mode: 'view'
    }),
    params: {
      title: 'View Rule:'
    },
  },
  {
    path: ROUTE_CONSTANTS.RULE_BUILDER_UPDATE_RULE,
    element: <PromotionWizard />,
    loader: () => ({
      mode: 'update'
    }),
    params: {
      title: 'Update Rule:',
    },
  },
  {
    path: ROUTE_CONSTANTS.PROMOTIONS_CALENDAR,
    element: <PromotionCalendar />,
    params: {
      title: 'Promotions Calendar',
    },
  },
  {
    path: ROUTE_CONSTANTS.VOUCHER_MANAGER,
    element: <VoucherManager />,
    params: {
      title: 'Voucher Manager',
    },
  },
  {
    path: ROUTE_CONSTANTS.VOUCHER_MANAGER_VIEW_VOUCHER,
    element: <VoucherBook />,
    params: {
      title: 'Voucher Book',
    },
  },
  {
    path: ROUTE_CONSTANTS.SPECIAL_DATES,
    element: <PromotionsSpecialDates />,
    params: {
      title: 'Special Dates',
    },
  },
];

export default PromotionsRouteObject;
