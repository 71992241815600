import { store } from '../Store/Store';
import { userSelector } from '@/Store/SolutionSlice';

const getSessionToken = () => {
  return userSelector(store.getState()).SessionToken;
};

const hasPermission = (permission: string) => {
  const hasOrOperator = permission.indexOf('||') > -1;
  const hasAndOperator = permission.indexOf('&&') > -1;

  if (hasOrOperator && hasAndOperator) {
    throw new Error('A permission statement may not contain both || and && operators.');
  }

  if (!store) {
    return false;
  }

  const requiredPermissions = (permission || '').split(hasAndOperator ? '&&' : '||');
  const permissions = store.getState().solution.user.Permissions;

  return hasAndOperator
    ? !requiredPermissions.find(item => !permissions.find(permission => item.trim() === permission.toString()))
    : requiredPermissions.find(item => permissions.find(permission => item.trim() === permission.toString()));
};

export { getSessionToken, hasPermission };
